import { DateTime } from 'luxon'
import { getCookie, removeCookie, setCookie } from 'typescript-cookie'

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: <Api.ApiUserResponse | null>null,
        token: getCookie('token') ?? null,
        expires_in: DateTime.now().plus({ days: 2 }).endOf('day').toISO(),
    }),

    getters: {
        check: state => Boolean(state.user?.id ?? false),
        admin: state => Boolean(state.user?.admin ?? false),
        localActivo: state => state.user?.sucursal_id ?? null,
    },

    actions: {
        changeSucursalActiva(sucursal_id: number | null) {
            if (this.user !== null) {
                this.user.sucursal_id = sucursal_id
            }
        },

        saveToken({ access_token, expires_in }: Api.ApiLoginResponse) {
            this.token = access_token
            this.expires_in = expires_in

            this.setToken()
        },

        setToken() {
            setCookie('token', this.token, {
                expires: DateTime.fromISO(this.expires_in).toJSDate(),
                sameSite: 'lax',
                path: '/',
            })

            window.axios.defaults.headers.Authorization = `Bearer ${this.token}`
        },

        removeToken() {
            removeCookie('token', {
                path: '/',
            })

            this.$reset()
        },

        async fetchUser() {
            this.token = getCookie('token') ?? null

            if (this.token !== null) {
                await window.axios.get<Api.ApiUserResponse>('/api/user').then((response) => {
                        this.user = response.data
                    }).catch(async () => {
                        await this.logout()
                    })

                await useCoreStore().fetchCoreData(true)
            }
        },

        async login(username: string, password: string) {
            const { routerPush } = useRouterPush()

            if (this.token !== null) {
                routerPush('Dashboard')

                return
            }

            await window.axios.post<Api.ApiLoginResponse>('/api/login', {
                    username,
                    password,
                }).then(async (response) => {
                    this.saveToken(response.data)

                    await this.fetchUser()

                    routerPush('Dashboard')
                })
        },

        async logout() {
            await window.axios.post('/api/logout').then(() => {
                this.logoutCleaner()
            })
        },

        logoutCleaner() {
            const { routerPush } = useRouterPush()

            window.Echo.leaveChannel('update')

            this.removeToken()

            window.axios.defaults.headers.Authorization = null

            routerPush('Login')
        },
    },

    share: {
        enable: true,
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
