<script setup lang="ts">
    import ConfirmationDialog from '@/app/ConfirmationDialog.vue'
    import RenderingPage from '@/app/RenderingPage.vue'
    import { lightTheme, Notification, NotificationProgress, Notivue, NotivueSwipe, outlinedIcons, slateTheme } from 'notivue'

    const coreStore = useCoreStore()

    useHead({
        titleTemplate: '%s %separator %siteName',
        templateParams: {
            siteName: 'ANDROMEDA',
            separator: '·',
        },
    })
</script>

<template>
    <router-view />

    <notivue v-slot="item">
        <notivue-swipe :item="item">
            <notification :item="item" :theme="coreStore.darkMode ? slateTheme : lightTheme" :icons="outlinedIcons">
                <notification-progress :item="item" />
            </notification>
        </notivue-swipe>
    </notivue>

    <confirmation-dialog />

    <rendering-page />
</template>
