import type { RouteLocationRaw, RouteParamValueRaw, RouteRecordName } from 'vue-router'

export function useRouterPush() {
    return {
        routerPush: (routeName: RouteRecordName, id?: RouteParamValueRaw): void => {
            const pushLocation: RouteLocationRaw = {
                name: routeName,
            }

            if (typeof id !== 'undefined') {
                pushLocation.params = { id }
            }

            void window.router.push(pushLocation)
        },
    }
}
